import React, { Component } from "react";
import PropTypes from "prop-types";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import "../css/App.css";


export class InvalidApiKey extends Component {

  render() {
    const { apiKey, apiErrorMsg, setApiKey, initPTClient, showDialog } = this.props;

    return (
      <Fabric>
        <div>
          {
            /*
            <span className="ms-font-m ms-fontColor-themePrimary">
            {productDefinition}
            <PrimaryButton
              text="Get API Key"
              style={{ height: '35px', width: '100%' }}
              onClick={() => this.showDialog("https://app.perfecttense.com/home")}
            />
            <p></p>
            </span>
            */
          }
          <p>
            You need a Perfect Tense API key to activate this add-in.
            If you do not have an API key, contact us to get started!
          </p>
          <PrimaryButton
            text="Contact Perfect Tense"
            style={{ height: "35px", width: "100%" }}
            onClick={showDialog}
          />
          <p><br /></p>
          <TextField
            label="Enter your API key below to get started with Perfect Tense and Word!"
            placeholder="Your API Key"
            errorMessage={apiErrorMsg}
            onChange={setApiKey}
          />
          <PrimaryButton
            type="submit"
            allowDisabledFocus={true}
            disabled={!apiKey}
            text="Submit"
            style={{ height: "35px", width: "140px" }}
            onClick={initPTClient}
          />
        </div>
      </Fabric>
    );
  }
}

InvalidApiKey.propTypes = {
  apiKey: PropTypes.string,
  apiErrorMsg: PropTypes.string,
  setApiKey: PropTypes.func.isRequired,
  initPTClient: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired
};
