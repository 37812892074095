import React, { Component } from "react";
import PropTypes from "prop-types";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import { Link } from "office-ui-fabric-react/lib/Link";
import "../css/App.css";


export class About extends Component {

  render() {
    const { showModal, isModalOpen, closeModal } = this.props;

    return (
      <Fabric>
        <div>
          <p>
            Perfect Tense is an intelligent spelling and grammar correction tool that can find and fix complex errors in your text.
          </p>
          <DefaultButton
            secondaryText="Opens the About Modal"
            style={{ height: "35px", width: "100%" }}
            onClick={showModal}
            text="About Perfect Tense"
          />
          <Modal
            isOpen={isModalOpen}
            onDismiss={closeModal}
            isBlocking={false}
            containerClassName="ms-modalExample-container"
          >
            <div className="padding">
              <p>
                <b>Perfect Tense</b> is smarter than the average spell checker. Our proprietary artificial intelligence
                understands the context and meaning of text to properly identify and fix errors with unparalleled accuracy.{" "}
              </p>
              <p><b>Here&#39;s how to use the Perfect Tense add-in for Word:</b></p>
              <p><b>1.</b> Enter your API key to connect Perfect Tense and Word.</p>
              <p><b>2.</b> Open the Perfect Tense add-in by clicking the bulb on your tool bar.</p>
              <p><b>3.</b> Click <b>&#34;Proofread my document&#34;</b> to have Perfect Tense find all errors.</p>
              <p><b>4.</b> Accept or reject corrections individually or speed up the process by accepting all corrections with one click.</p>
              <p><b>
                <Link href="https://www.perfecttense.com/integrations/microsoft-word">Click here </Link>
                for more information about the plugin, or <Link href="https://www.perfecttense.com/contact"> contact support </Link>
                if you need help.{" "}
              </b></p>
            </div>
          </Modal>
        </div>
      </Fabric>
    );
  }
}

About.propTypes = {
  showModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
