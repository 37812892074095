import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";


const main = async () => {
  await window.Office.onReady();
  ReactDOM.render(<App />, document.getElementById("root"));
}

main();
