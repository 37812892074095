import React, { Component } from "react";
import PropTypes from "prop-types";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import { Link } from "office-ui-fabric-react/lib/Link";
import { Image } from "office-ui-fabric-react/lib/Image";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import bulb from "../img/pt_bulb.png";
import "../css/App.css";


export class Tutorial extends Component {

  render() {
    const { showModal, isModalOpen, closeModal, removeKey, reqRemains } = this.props;

    return (
      <Fabric>
        <div>
          <DefaultButton
            secondaryText="Opens the About Modal"
            style={{ height: "35px", width: "100%" }}
            onClick={showModal}
            text="How to use the add-in"
          />
          <Modal
            isOpen={isModalOpen}
            onDismiss={closeModal}
            isBlocking={false}
            containerClassName="ms-modalExample-container"
          >
            <div className="padding">
              <p>
                <b>Perfect Tense</b> is smarter than the average spell checker. Our proprietary artificial intelligence
                understands the context and meaning of text to properly identify and fix errors with unparalleled accuracy.{" "}
              </p>
              <p><b>Here&#39;s how to use the Perfect Tense add-in for Word:</b></p>
              <p><b>1.</b> Click <b>&#34;Proofread Your Document&#34;</b> to have Perfect Tense find all errors.</p>
              <p><b>2.</b> Accept or reject corrections individually or speed up the process by accepting all corrections with one click.</p>
              <span>
                <b>Tip:</b> Click the below lightbulb icon to highlight the specific mistake in your document.
                <Image src={bulb} width={32} height={32} />
                A grammar score is calculated for each paragraph in your document.
              </span>
              <p><b>
                <Link href="https://www.perfecttense.com/integrations/microsoft-word">Click here </Link>
                for more information about the plugin, or <Link href="https://www.perfecttense.com/contact"> contact support </Link>
                if you need help.{" "}
              </b></p>
              {reqRemains &&
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  /* onDismiss={console.log('this is a test.')} */
                  isMultiline={true}
                  dismissButtonAriaLabel="Close">
                  <span className="ms-font-m">Your remaining requests: {reqRemains}{" "}</span>
                </MessageBar>
              }
              <PrimaryButton
                type="submit"
                text="Remove API Key"
                style={{ height: "35px", width: "100%" }}
                onClick={removeKey}
              />
            </div>
          </Modal>
        </div>
      </Fabric>
    );
  }
}

Tutorial.propTypes = {
  showModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  removeKey: PropTypes.func.isRequired,
  reqRemains: PropTypes.number
};
