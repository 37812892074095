
export class CorrectionRange {

  constructor(text, index) {
    this.text = text;
    this.index = index;
    this.interactiveEditor = null;
    this.isDone = false;
    this.rangeID = Math.random().toString().slice(2);
  }

  set rangeID(value) { this._rangeID = value; }
  set editor(value) { this._interactiveEditor = value; }
  set text(value) { this._text = value; }
  set index(value) { this._index = value; }
  set isDone(value) { this._isDone = value; }

  get rangeID() { return this._rangeID; }
  get editor() { return this._interactiveEditor; }
  get text() { return this._text; }
  get index() { return this._index; }
  get isDone() { return this._isDone; }

  get availableTransformations() {
    this.filterInvalidTransformation();
    return this._interactiveEditor.getAllAvailableTransforms();
  }

  get transformations() {
    if (!this._interactiveEditor) return [];
    const that = this;
    const sentIdxMap = new Map();
    this.filterInvalidTransformation();

    return this._interactiveEditor.getAllAvailableTransforms()
      .sort(function (t1, t2) {
        if (t1.sentenceIndex === t2.sentenceIndex)
          return t1.tokensAffected[0].id - t2.tokensAffected[0].id;
        else return t1.sentenceIndex - t2.sentenceIndex;
      })
      .map(function (t) {
        const extracted = that.extractInfoFromTransformation(t);
        const sentSearchIndex = that.countSameSentBefore(
          sentIdxMap, extracted.sentBeforeTransform, t.sentenceIndex);

        return {
          ptTransformation: t,
          sentSearchIndex: sentSearchIndex,
          ...extracted
        };
      });
  }

  extractInfoFromTransformation = (t) => {
    const beforeSentence = this.editor.getSentenceFromTransform(t);
    const sentBeforeTransform = this.editor.getCurrentSentenceText(beforeSentence).trimEnd();
    const affectedText = this.editor.getAffectedText(t);
    const addedText = this.editor.getAddedText(t);
    const affectedSearchIndex = this.countFreqBefore(
      sentBeforeTransform, affectedText, this.editor.getTransformOffset(t));

    return {
      affectedSearchIndex: affectedSearchIndex,
      affectedText: affectedText,
      addedText: addedText,
      sentBeforeTransform: sentBeforeTransform
    };
  }

  filterInvalidTransformation = () => {
    const that = this;

    this._interactiveEditor.getAllAvailableTransforms().forEach(function (t) {
      const activeTokens = that.editor.getSentenceFromTransform(t).activeTokens;
      const isValid = t.tokensAffected.every(function (token) {
        return activeTokens.some(at => at.id === token.id);
      });
      if (!isValid) that.editor.rejectCorrection(t);
    });
  }

  countSameSentBefore = (sentIdxMap, sentStr, sentIdx) => {
    if (sentIdxMap.has(sentStr)) {
      const prevSents = sentIdxMap.get(sentStr);
      if (prevSents[prevSents.length - 1] !== sentIdx) {
        prevSents.push(sentIdx);
        sentIdxMap.set(sentStr, prevSents);
      }
      return prevSents.length - 1;
    }
    else {
      sentIdxMap.set(sentStr, Array.of(sentIdx));
      return 0;
    }
  }

  escapeString = (str) => {
    //replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
  }

  countFreqBefore = (fullStr, findStr, findStrOffset) => {
    const beforeStr = fullStr.substring(0, findStrOffset);
    const regex = new RegExp(this.escapeString(findStr), 'g');
    return (beforeStr.match(regex) || []).length;
  }
}
