import React, { Component } from "react";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { initializeIcons } from "@uifabric/icons";

import { InvalidApiKey, Corrections, About, Tutorial, ErrorBoundary } from "./components";
import { CorrectionRange, PTClient } from "./utils";
import logo from "./img/pt_logo.png";
import "./css/App.css";


class App extends Component {

  constructor(props) {
    super(props);
    initializeIcons();

    this.state = {
      reqRemains: null,
      hasValidApiKey: null,
      hasWarning: null,
      isModalOpen: false,
      apiKey: localStorage.getItem("apiKey"),
      availableCorrections: [],
      ongoingCorrections: []
    };

    if (this.state.apiKey) this.initPTClient();
    this.style = "text, font/size, style";
    this.paragraphs = [];
    this.maxParagraphLength = 10000;
  }

  clearCorrections = (isCorrecting) => {
    this.paragraphs = [];

    this.setState({
      hasWarning: null,
      correctDisabled: isCorrecting,
      availableCorrections: [],
      ongoingCorrections: []
    });
  }

  clearCorrection = (correctionRange) => {
    correctionRange.isDone = true;
    if (this.state.ongoingCorrections.length === 0 &&
      this.paragraphs.every(p => p.isDone)) this.clearCorrections(false);
  }

  updateAvailableCorrections = (correctionRange, isNew) => {
    if (this.state.correctDisabled && correctionRange.availableTransformations.length > 0) {
      if (isNew) this.setState((state) => ({ availableCorrections: [...state.availableCorrections, correctionRange.index] }));
      else this.setState((state) => ({ availableCorrections: state.availableCorrections }));
    }
    else {
      this.setState((state) => ({ availableCorrections: state.availableCorrections.filter(i => i !== correctionRange.index) }));
      this.clearCorrection(correctionRange);
    }
  }

  getAvailableCorrectionRanges = () => {
    return this.state.availableCorrections
      .sort((i1, i2) => i1 - i2)
      .map(i => this.paragraphs.find(paragraph => paragraph.index === i));
  }

  onAcceptCorrection = (correction, correctionRange) => {
    window.Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      paragraphs.load(this.style);
      await context.sync();

      if (correctionRange.index < paragraphs.items.length) {
        const sentSearch = paragraphs.items[correctionRange.index].search(correction.sentBeforeTransform);
        sentSearch.load(this.style);
        await context.sync();
        const affectedSearch = sentSearch.items[correction.sentSearchIndex].search(correction.affectedText);
        affectedSearch.load(this.style);
        await context.sync();

        const affectedRange = affectedSearch.items[correction.affectedSearchIndex];
        affectedRange.load(this.style);
        await context.sync();

        affectedRange.insertText(correction.addedText, "Replace");
        correctionRange.editor.acceptCorrection(correction.ptTransformation);
        this.updateAvailableCorrections(correctionRange, false);
      }
      return context.sync();
    }).catch(this.handleError);
  }

  onRejectCorrection = (correction, correctionRange) => {
    correctionRange.editor.rejectCorrection(correction.ptTransformation);
    this.updateAvailableCorrections(correctionRange, false);
  }

  onSelectCorrection = (correction, correctionRange) => {
    window.Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      paragraphs.load(this.style);
      await context.sync();

      if (correctionRange.index < paragraphs.items.length) {
        const sentSearch = paragraphs.items[correctionRange.index].search(correction.sentBeforeTransform);
        sentSearch.load(this.style);
        await context.sync();
        const affectedSearch = sentSearch.items[correction.sentSearchIndex].search(correction.affectedText);
        affectedSearch.load(this.style);
        await context.sync();

        affectedSearch.items[correction.affectedSearchIndex].select();
      }
      return context.sync();
    }).catch(this.handleError);
  }

  applyAllCorrections = () => {
    window.Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      paragraphs.load(this.style);
      await context.sync();

      for (let correctionRange of this.paragraphs) {
        if (!correctionRange.isDone && correctionRange.index < paragraphs.items.length) {
          while (correctionRange.editor.hasNextTransform()) {
            const t = correctionRange.editor.getNextTransform();
            const correction = correctionRange.extractInfoFromTransformation(t);

            const sentSearch = paragraphs.items[correctionRange.index].search(correction.sentBeforeTransform);
            sentSearch.load(this.style);
            await context.sync();
            const affectedSearch = sentSearch.getFirst().search(correction.affectedText);
            affectedSearch.load(this.style);
            await context.sync();

            const affectedRange = affectedSearch.items[correction.affectedSearchIndex];
            affectedRange.load(this.style);
            await context.sync();

            affectedRange.insertText(correction.addedText, "Replace");
            correctionRange.editor.acceptCorrection(t);
          }
          this.clearCorrection(correctionRange);
        }
      }

      this.clearCorrections(false);
      return context.sync();
    }).catch(this.handleError);
  }

  ignoreAllCorrections = () => {
    for (let paragraph of this.paragraphs) {
      if (!paragraph.isDone) this.clearCorrection(paragraph);
    }
    this.clearCorrections(false);
  }

  handleError = (error) => {
    console.log("Error: " + error);
    if (error instanceof window.OfficeExtension.Error) {
      console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
  }

  correctBodyInParagraphs = () => {
    const that = this;
    this.clearCorrections(true);

    window.Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      paragraphs.load(this.style);
      await context.sync();

      if (paragraphs.items.length <= 0 || paragraphs.items.every(p => p.text.trim() === "")) {
        that.clearCorrections(false);
      }
      else {
        that.setState((state) => ({ ongoingCorrections: [...Array(paragraphs.items.length).keys()] }));

        for (var i = 0; i < paragraphs.items.length; i++) {
          if (paragraphs.items[i].text.trim() === "") that.removeFromOngoingCorrections(i);
          else {
            const correctionRange = new CorrectionRange(paragraphs.items[i].text, i);
            that.paragraphs.push(correctionRange);
            that.requestCorrection(correctionRange);
            await new Promise((resolve, reject) => setTimeout(resolve, 500));
          }
        }
      }
      return context.sync();
    }).catch(this.handleError);
  }

  removeFromOngoingCorrections = (index) => {
    this.setState((state) => ({ ongoingCorrections: state.ongoingCorrections.filter(i => i !== index) }));
  }

  updateCorrectionsState = (correctionRange, isFailed) => {
    this.removeFromOngoingCorrections(correctionRange.index);
    if (isFailed) this.clearCorrection(correctionRange);
    else {
      this.checkUsageStat();
      this.updateAvailableCorrections(correctionRange, true);
    }
  }

  requestCorrection = async (correctionRange) => {
    try {
      const localApiKey = localStorage.getItem("apiKey");
      var text = correctionRange.text;
      if (text.length > this.maxParagraphLength) {
        this.setState({ hasWarning: true });
        text = text.substring(0, this.maxParagraphLength - 1);
      }

      const result = await PTClient.submitJob(text, localApiKey);
      this.onCorrectionSuccess(result, correctionRange);
    }
    catch (error) {
      this.onCorrectionFailure(error, correctionRange);
    }
  }

  onCorrectionSuccess = (result, correctionRange) => {
    const localApiKey = localStorage.getItem("apiKey");
    const interactiveEditor = PTClient.interactiveEditor({
      data: result,
      apiKey: localApiKey,
      ignoreNoReplacement: true
    });
    correctionRange.editor = interactiveEditor;
    this.updateCorrectionsState(correctionRange, false);
  }

  onCorrectionFailure = (error, correctionRange) => {
    switch (error.status) {
      case 401:
        // User's API key is invalid/expired
        this.killPTClient("Your API key is invalid!");
        break;
      case 403:
        // User is over their daily API limit
        console.log("403");
        break;
      default:
        // Other error - see API documentation
        console.log("Other errors");
    }
    this.updateCorrectionsState(correctionRange, true);
  }

  checkUsageStat = async () => {
    const localApiKey = localStorage.getItem("apiKey");
    const result = await PTClient.getUsage(localApiKey);
    this.setState({ reqRemains: result.data.apiRemainToday });
  }

  callbackDisplay = (result) => {
    if (result.status !== window.Office.AsyncResultStatus.Succeeded) return;

    var dialog = result.value;
    dialog.addEventHandler(
      window.Office.EventType.DialogMessageReceived,
      function (AsyncResult) {
        if (AsyncResult.type !== window.Office.EventType.DialogMessageReceived) return;
        var data = JSON.parse(AsyncResult.message);
        console.log(data);
        dialog.close();
      }
    );
  }

  showDialog = (link) => {
    if (window.Office.context.requirements.isSetSupported("DialogApi", 1.1)) {
      window.Office.context.ui.displayDialogAsync(
        link,
        { width: 60, height: 80, requireHTTPS: true },
        this.callbackDisplay
      );
    }
  }

  getAppKey = async (apiKey) => {
    try {
      const localAppKey = localStorage.getItem(`appKey_${apiKey}`);
      if (localAppKey) return localAppKey;
      const response = await PTClient.generateAppKey(apiKey, "Office Word Add-in", "", "");
      const appKey = response.key;
      localStorage.setItem(`appKey_${apiKey}`, appKey);
      return appKey;
    }
    catch (error) {
      throw new Error("Failed to create App key!");
    }
  }

  initPTClient = async () => {
    try {
      const apiKey = this.state.apiKey;
      const isValid = await PTClient.apiKeyIsValid(apiKey);
      if (!isValid) throw new Error("Your API key is invalid!");

      this.setState({ hasValidApiKey: true, apiFormErrorMsg: "" });
      localStorage.setItem("apiKey", apiKey);
      const appKey = await this.getAppKey(apiKey);

      PTClient.initialize({
        appKey: appKey,
        options: { "dictionaries": ["medical", "legal"] }
      });
      this.checkUsageStat();
    }
    catch (error) {
      this.killPTClient(error.message);
    }
  }

  killPTClient = async (errorMsg) => {
    this.setState(
      {
        hasValidApiKey: false,
        isModalOpen: false,
        apiKey: null,
        apiFormErrorMsg: errorMsg
      },
      () => { localStorage.clear(); }
    );
  }

  onShowModal = () => { this.setState({ isModalOpen: true }); }
  onCloseModal = () => { this.setState({ isModalOpen: false }); }

  onContactButtonClicked = () => { this.showDialog("https://www.perfecttense.com/"); }
  onApiKeyTextFieldChanged = (ev, apiKey) => { this.setState({ apiKey: apiKey }); }
  onApiKeyRemoved = () => { this.killPTClient(""); }

  render() {
    const { correctDisabled, hasValidApiKey, hasWarning, isModalOpen, reqRemains,
      apiKey, apiFormErrorMsg, availableCorrections, ongoingCorrections } = this.state;

    let mainbody;
    // Page when users did not submit their API key
    if (!hasValidApiKey) {
      mainbody = (
        <div>
          <About
            isModalOpen={isModalOpen}
            showModal={this.onShowModal}
            closeModal={this.onCloseModal}
          /><br />
          <InvalidApiKey
            apiKey={apiKey}
            apiErrorMsg={apiFormErrorMsg}
            setApiKey={this.onApiKeyTextFieldChanged}
            initPTClient={this.initPTClient}
            showDialog={this.onContactButtonClicked}
          />
        </div>
      );
    }
    // Page when there are corrections to display
    else if (availableCorrections.length > 0) {
      mainbody = (
        <Corrections
          isFetching={ongoingCorrections.length > 0}
          hasWarning={hasWarning}
          applyAllCorrections={this.applyAllCorrections}
          ignoreAllCorrections={this.ignoreAllCorrections}
          correctionRanges={this.getAvailableCorrectionRanges()}
          onAcceptCorrection={this.onAcceptCorrection}
          onRejectCorrection={this.onRejectCorrection}
          onSelectCorrection={this.onSelectCorrection}
        />
      );
    }
    // Page after users submitted the API key
    else {
      mainbody = (
        <div>
          <p>
            <b>Perfect Tense</b> is an intelligent spelling and grammar correction tool
            that can find and fix complex errors in your text.{" "}
          </p>
          <TooltipHost
            content="We will proofread your whole document!"
            id="tip_body"
            calloutProps={{ gapSpace: 0 }}
            closeDelay={500}
          >
            <DefaultButton
              primary={true}
              allowDisabledFocus={true}
              disabled={correctDisabled}
              text="Proofread Your Document"
              style={{ height: "35px", width: "100%" }}
              onClick={this.correctBodyInParagraphs}
            />
          </TooltipHost>
          <p>
            <Tutorial
              isModalOpen={isModalOpen}
              showModal={this.onShowModal}
              closeModal={this.onCloseModal}
              removeKey={this.onApiKeyRemoved}
              reqRemains={reqRemains}
            />
          </p>
        </div>
      );
    }

    return (
      <Fabric>
        <div id="content">
          <div id="content-header">
            <div className="padding">
              <Image
                src={logo}
                imageFit={ImageFit.contain}
                alt="Perfect Tense Logo"
                height={110}
              />
            </div>
          </div>
          <div id="content-main"><div className="padding">
            <ErrorBoundary>
              {ongoingCorrections.length > 0 && <Spinner label="Correcting..." size={SpinnerSize.large} ariaLive="assertive" />}
              {mainbody}
            </ErrorBoundary>
          </div></div>
        </div>
      </Fabric>
    );
  }
}

export default App;
