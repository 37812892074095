import React, { Component } from "react";
import PropTypes from "prop-types";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import { CorrectionRange } from "../utils";
import { Correction } from "../components";
import "../css/App.css";


export class Corrections extends Component {

  render() {
    const { isFetching, hasWarning, correctionRanges, applyAllCorrections, ignoreAllCorrections,
      onAcceptCorrection, onRejectCorrection, onSelectCorrection } = this.props;

    return (
      <Fabric>
        <div>
          <div>
            <DefaultButton
              primary
              text="Apply All"
              style={{ height: "35px", width: "140px" }}
              disabled={isFetching}
              onClick={applyAllCorrections}
            />
            <DefaultButton
              text="Ignore All"
              style={{ height: "35px", width: "140px" }}
              disabled={isFetching}
              onClick={ignoreAllCorrections}
            />
          </div>
          {hasWarning && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}><span className="ms-font-m">
            Note: Perfect Tense only corrects the first 10,000 characters of each paragraph.{" "}</span></MessageBar>}
          {correctionRanges.map(correctionRange => (
            <div key={"correction" + correctionRange.rangeID}>
              <Correction
                correctionRange={correctionRange}
                onAcceptCorrection={onAcceptCorrection}
                onRejectCorrection={onRejectCorrection}
                onSelectCorrection={onSelectCorrection}
              />
            </div>
          ))}
        </div>
      </Fabric>
    );
  }
}

Corrections.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  hasWarning: PropTypes.bool,
  applyAllCorrections: PropTypes.func.isRequired,
  ignoreAllCorrections: PropTypes.func.isRequired,
  correctionRanges: PropTypes.arrayOf(PropTypes.instanceOf(CorrectionRange)).isRequired,
  onAcceptCorrection: PropTypes.func.isRequired,
  onRejectCorrection: PropTypes.func.isRequired,
  onSelectCorrection: PropTypes.func.isRequired
};
