import React, { Component } from "react";
import PropTypes from "prop-types";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import { FocusZone, FocusZoneDirection } from "office-ui-fabric-react/lib/FocusZone";
import { List } from "office-ui-fabric-react/lib/List";
import { CorrectionRange } from "../utils";
import bulb from "../img/pt_bulb.png";
import "../css/App.css";


export class Correction extends Component {

  getCorrectionElement = (transformation) => {
    const { onSelectCorrection, onRejectCorrection, onAcceptCorrection, correctionRange } = this.props;
    const onAccept = () => onAcceptCorrection(transformation, correctionRange);
    const onReject = () => onRejectCorrection(transformation, correctionRange);
    const onSelect = () => onSelectCorrection(transformation, correctionRange);

    return (
      <div className="correction-itemCell" data-is-focusable={true}>
        <Image
          className="correction-itemImages"
          src={bulb}
          width={32}
          height={32}
          imageFit={ImageFit.cover}
          title="Select"
          onClick={onSelect}
        />
        <div className="correction-itemContent">
          <div className="correction-itemDesc">
            <span className="ms-font-m ms-fontWeight-semibold">
              {transformation.ptTransformation.message}
            </span>
          </div>
          <div className="correction-itemName">
            <span className="ms-font-m ms-fontWeight-bold ms-fontColor-themePrimary">
              {transformation.affectedText} &rarr; {transformation.addedText}
            </span>
          </div>
          <p></p>
          <DefaultButton
            primary={true}
            text="Accept"
            style={{ height: "35px", width: "80px" }}
            onClick={onAccept}
          />
          <DefaultButton
            text="Reject"
            style={{ height: "35px", width: "80px" }}
            onClick={onReject}
          />
        </div>
      </div>
    );
  }

  render() {
    const { correctionRange } = this.props;

    return (
      <Fabric>
        <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
          <span className="ms-font-m">
            Your grammar score: {correctionRange.editor.getGrammarScore()}/100{" "}
          </span>
        </MessageBar>
        <FocusZone direction={FocusZoneDirection.vertical}>
          <List
            items={correctionRange.transformations}
            onRenderCell={this.getCorrectionElement}
          />
        </FocusZone>
      </Fabric>
    );
  }
}

Correction.propTypes = {
  onSelectCorrection: PropTypes.func.isRequired,
  onAcceptCorrection: PropTypes.func.isRequired,
  onRejectCorrection: PropTypes.func.isRequired,
  correctionRange: PropTypes.instanceOf(CorrectionRange).isRequired
};
